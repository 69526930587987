import { atom } from "jotai";
import { EMRPatient } from "@/lib/types/patient";
import { EMRAppointment } from "@/lib/types/appointment";
import { OrderType } from "@/lib/types/encounterNote";
import { AddressBookOrganization, AddressBookOther, AddressBookPhysician } from "@/lib/types/addressBook";
import { Pharmacy } from "@/lib/types/pharmacy";
import { RefObject } from "react";
import { EncounterNotesHandle } from "@/components/encounterNotes/encounterNotes";

export type ClinicalChartTabItems = "patientChart" | "encounter";
export type AppointmentsTabItems = "daySheet" | "calendar";
export type TemplateManagerTabItems = "templateManager" | "componentManager" | "hotPhrasesManager";
export type DocumentsTabItems = "pending" | "inbox" | null;

export const selectedPatientAtom = atom<EMRPatient | null>(null); // key: "selectedPatient"

export const pharmacyListAtom = atom([] as Pharmacy[]); // key: "pharmacyList"

export const selectedPharmacyAtom = atom({} as Pharmacy); // key: "selectedPharmacy"

export const selectedAddressBookPharmacyAtom = atom({} as Pharmacy); // key: "selectedAddressBookPharmacy"

export const selectedAppointmentAtom = atom({} as EMRAppointment); // key: "selectedAppointment"

export const encounterNoteIdAtom = atom(null as number | null); // key: "encounterNoteId"

export const clinicalChartTabAtom = atom("patientChart" as ClinicalChartTabItems); // key: "clinicalChartTab"

export const templateManagerTabAtom = atom("templateManager" as TemplateManagerTabItems); // key: "templateManagerTab"

export const documentsTabAtom = atom(null as DocumentsTabItems); // key: "documentsTab"

interface IUnsavedState {
  unsaved: boolean;
}

export const unsavedStateAtom = atom({ unsaved: false } as IUnsavedState); // key: "unsavedState"

export const selectedAddressBookOrganizationAtom = atom({} as AddressBookOrganization); // key: "selectedAddressBookOrganization"

export const selectedAddressBookOtherAtom = atom({} as AddressBookOther); // key: "selectedAddressBookOther"

export const selectedAddressBookPhysicianAtom = atom({} as AddressBookPhysician); // key: "selectedAddressBookPhysician"

export const orderDraftsAtom = atom([] as OrderType[]); // key: "orderDrafts"

export const encounterNotesRefAtom = atom(null as RefObject<EncounterNotesHandle | null> | null);

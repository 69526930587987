import { PopoverContent, PopoverTrigger, Popover as SCNPopover } from "@/components/ui/popover";
import { Button as SCNButton } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import Icon from "@mdi/react";
import { mdiCloseCircleOutline, mdiMenuDown } from "@mdi/js";
import { cn } from "@/lib/utils/cssUtils";
import { Dispatch, KeyboardEvent, SetStateAction, useEffect, useRef, useState } from "react";
import { isNil } from "lodash";
import { Tooltip as SCNTooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { ClassValue } from "clsx";
import { useControllableState } from "@radix-ui/react-use-controllable-state";
import { ComboItem } from "./combobox";
export interface FuzzyComboboxItem extends ComboItem {
  hide?: boolean;
}
interface FuzzyComboboxProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  currentValue: string | null;
  data: FuzzyComboboxItem[] | null | undefined;
  onSelect?: (value: string) => void;
  onDeselect?: () => void;
  allowRemove?: boolean;
  containerClassName?: ClassValue;
  popoverTriggerClassName?: ClassValue;
  popoverTriggerTextClassName?: ClassValue;
  popoverContentClassName?: ClassValue;
  popoverContentInnerContainerClassName?: ClassValue;
  popoverContentItemClassName?: ClassValue;
  popoverContentSelectedItemClassName?: ClassValue;
  popoverContentItemTextClassName?: ClassValue;
  popoverContentSelectedItemTextClassName?: ClassValue;
  popoverContentUsePortal?: boolean;
  popoverContentAlignment?: "start" | "center" | "end";
  placeholder?: string;
  showTriggerTooltip?: boolean;
  onSearchKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  dropdownHeight?: number | null;
  disabled?: boolean;
  disableChevron?: boolean;
  searchText: string | null;
  setSearchText: Dispatch<SetStateAction<string | null>>;
  showDisplayValue?: boolean;
  isDataLoading?: boolean;
}
export const FuzzyCombobox = ({
  open: openProp,
  onOpenChange,
  currentValue,
  data,
  onSelect,
  onDeselect,
  allowRemove = false,
  containerClassName,
  popoverTriggerClassName,
  popoverTriggerTextClassName,
  popoverContentClassName,
  popoverContentInnerContainerClassName,
  popoverContentItemClassName,
  popoverContentSelectedItemClassName,
  popoverContentItemTextClassName,
  popoverContentSelectedItemTextClassName,
  popoverContentUsePortal = true,
  popoverContentAlignment = "start",
  placeholder,
  showTriggerTooltip = false,
  onSearchKeyDown,
  dropdownHeight = 400,
  disabled = false,
  disableChevron = false,
  searchText,
  setSearchText,
  showDisplayValue = true,
  isDataLoading = false
}: FuzzyComboboxProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [open = false, setOpen] = useControllableState({
    prop: openProp,
    defaultProp: false,
    onChange: onOpenChange
  });
  const [selectedItem, setSelectedItem] = useState<FuzzyComboboxItem | null>(null);
  useEffect(() => {
    const item = data?.find(item => item.value === currentValue) ?? null;
    if (!isNil(item)) {
      setSelectedItem({
        ...item,
        hide: true
      });
    } else {
      setSelectedItem(null);
    }
  }, [currentValue, data]);
  return <>
      <div className={cn("relative flex w-36", containerClassName)}>
        <SCNPopover open={open} onOpenChange={setOpen} data-sentry-element="SCNPopover" data-sentry-source-file="fuzzyCombobox.tsx">
          <SCNTooltip data-sentry-element="SCNTooltip" data-sentry-source-file="fuzzyCombobox.tsx">
            <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="fuzzyCombobox.tsx">
              <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="fuzzyCombobox.tsx">
                <SCNButton variant="outline" className={cn("relative flex h-9 w-full min-w-10 justify-between gap-0.5 bg-primary py-0 pl-3 pr-2 text-sm shadow-sm hover:ring-1 disabled:opacity-50 disabled:hover:ring-0", popoverTriggerClassName)} aria-expanded={open} disabled={disabled} data-sentry-element="SCNButton" data-sentry-source-file="fuzzyCombobox.tsx">
                  <div className={cn("flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-start text-component-fg", isNil(currentValue) && "text-placeholder-fg", popoverTriggerTextClassName)}>
                    {isNil(currentValue) ? placeholder ?? "Select..." : isDataLoading ? "Loading..." : showDisplayValue ? selectedItem?.displayValue ?? selectedItem?.name ?? currentValue : selectedItem?.name ?? currentValue}
                  </div>
                  <div className="flex h-full items-center justify-end">
                    {!isNil(currentValue) && allowRemove && <div onClick={e => {
                    e.stopPropagation();
                    onDeselect && onDeselect();
                    setSelectedItem(null);
                  }} className="px-0.5 text-component-fg hover:text-blue-500">
                        <Icon path={mdiCloseCircleOutline} size={0.75} />
                      </div>}
                    {!disableChevron && <div className="pl-0.5">
                        <Icon path={mdiMenuDown} size={1} className="size-4 text-component-fg" />
                      </div>}
                  </div>
                </SCNButton>
              </PopoverTrigger>
            </TooltipTrigger>

            {showTriggerTooltip && !isNil(selectedItem) && !isDataLoading && <TooltipContent className="">
                {showDisplayValue ? selectedItem?.displayValue ?? selectedItem?.name ?? currentValue ?? "" : selectedItem?.name ?? currentValue ?? ""}
              </TooltipContent>}
          </SCNTooltip>

          <PopoverContent className={cn("z-[300] w-[var(--radix-popover-trigger-width)] p-0", popoverContentClassName)} disableArrow align={popoverContentAlignment} usePortal={popoverContentUsePortal} onOpenAutoFocus={e => {
          e.preventDefault();
          inputRef.current?.focus();
        }} data-sentry-element="PopoverContent" data-sentry-source-file="fuzzyCombobox.tsx">
            <Command loop shouldFilter={false} data-sentry-element="Command" data-sentry-source-file="fuzzyCombobox.tsx">
              <CommandInput ref={inputRef} placeholder="Search..." onValueChange={e => {
              setSearchText(e);
            }} value={searchText ?? ""} data-sentry-element="CommandInput" data-sentry-source-file="fuzzyCombobox.tsx" />

              {(data ?? []).length === 0 ? <></> : <>
                  {(searchText ?? "").length > 0 ? <CommandEmpty>No results found.</CommandEmpty> : <CommandEmpty>Enter a value to search.</CommandEmpty>}

                  {/* <CommandGroup
                    style={{
                      height: `${dropdownHeight}px`,
                      overflow: "hidden",
                    }}
                   > */}
                  <CommandList className={cn("max-h-[unset]", popoverContentInnerContainerClassName)} style={{
                ...(dropdownHeight && {
                  height: `${dropdownHeight}px`
                })
              }} onWheel={e => {
                e.stopPropagation();
              }}>
                    {data?.map(item => {
                  if (item?.hide ?? false) {
                    return null;
                  }
                  return <CommandItem key={item.value} value={item.value} onSelect={() => {
                    onSelect && onSelect(item.value);
                    setSelectedItem(item);
                    setOpen(false);
                  }} onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (onSearchKeyDown) {
                      return onSearchKeyDown(e);
                    }
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }} className={cn("cursor-pointer text-component-fg", popoverContentItemClassName, item.value === currentValue && "rounded-sm bg-gray-400 text-primary", item.value === currentValue && popoverContentSelectedItemClassName)}>
                          <SCNTooltip>
                            <TooltipTrigger asChild>
                              <div className={cn("flex w-full overflow-ellipsis", popoverContentItemTextClassName, item.value === currentValue && popoverContentSelectedItemTextClassName)}>
                                {item.name}
                              </div>
                            </TooltipTrigger>
                            {!isNil(item.tooltip) && <TooltipContent side="right">{item.tooltip}</TooltipContent>}
                          </SCNTooltip>
                        </CommandItem>;
                })}
                  </CommandList>
                  {/* </CommandGroup> */}
                </>}
            </Command>
          </PopoverContent>
        </SCNPopover>
      </div>
    </>;
};
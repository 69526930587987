"use client";

import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils/cssUtils";
import { ComponentProps, HTMLAttributes, ReactNode } from "react";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogPortal = DialogPrimitive.Portal;
const DialogClose = DialogPrimitive.Close;
type DialogCustomProps = {
  overlayClass?: string;
  closeClass?: string;
  closeCrossClass?: string;
  externalContent?: ReactNode;
  showCloseButton?: boolean;
  usePortal?: boolean;
  hideTitle?: boolean;
};
const DialogOverlay = ({
  className,
  overlayClass,
  ...props
}: ComponentProps<typeof DialogPrimitive.Overlay> & DialogCustomProps) => <DialogPrimitive.Overlay className={cn("fixed inset-0 z-[200] bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0", overlayClass, className)} {...props} data-sentry-element="unknown" data-sentry-component="DialogOverlay" data-sentry-source-file="dialog.tsx" />;
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
const DialogContent = ({
  className,
  children,
  closeClass,
  externalContent,
  overlayClass,
  showCloseButton = true,
  usePortal = true,
  hideTitle = false,
  ...props
}: ComponentProps<typeof DialogPrimitive.Content> & DialogCustomProps) => {
  return usePortal ? <DialogPortal data-sentry-element="DialogPortal" data-sentry-component="DialogContent" data-sentry-source-file="dialog.tsx">
      <DialogOverlay overlayClass={overlayClass} data-sentry-element="DialogOverlay" data-sentry-source-file="dialog.tsx" />
      {externalContent}
      <DialogPrimitive.Content className={cn("fixed left-[50%] top-[50%] z-[250] grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border bg-primary p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg", className)} {...props} aria-describedby={undefined} data-sentry-element="unknown" data-sentry-source-file="dialog.tsx">
        {hideTitle && <VisuallyHidden.Root>
            <DialogPrimitive.Title className="sr-only" />
          </VisuallyHidden.Root>}
        {children}
        {showCloseButton && <DialogPrimitive.Close className={cn("ring-offset-background focus:ring-ring data-[state=open]:text-component-fg absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-primary-hover", closeClass)}>
            <Cross2Icon className="size-4" />
            <span className="sr-only">Close</span>
          </DialogPrimitive.Close>}
      </DialogPrimitive.Content>
    </DialogPortal> : <>
      <DialogOverlay overlayClass={overlayClass} data-sentry-element="DialogOverlay" data-sentry-source-file="dialog.tsx" />
      {externalContent}
      <DialogPrimitive.Content className={cn("fixed left-[50%] top-[50%] z-[250] grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border bg-primary p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg", className)} {...props} aria-describedby={undefined} data-sentry-element="unknown" data-sentry-source-file="dialog.tsx">
        {hideTitle && <VisuallyHidden.Root>
            <DialogPrimitive.Title className="sr-only" />
          </VisuallyHidden.Root>}
        {children}
        {showCloseButton && <DialogPrimitive.Close className={cn("ring-offset-background focus:ring-ring data-[state=open]:text-component-fg absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-primary-hover", closeClass)}>
            <Cross2Icon className="size-4" />
            <span className="sr-only">Close</span>
          </DialogPrimitive.Close>}
      </DialogPrimitive.Content>
    </>;
};
DialogContent.displayName = DialogPrimitive.Content.displayName;
const DialogHeader = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => <div className={cn("flex flex-col space-y-1.5 text-center sm:text-left", className)} {...props} data-sentry-component="DialogHeader" data-sentry-source-file="dialog.tsx" />;
DialogHeader.displayName = "DialogHeader";
const DialogFooter = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => <div className={cn("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", className)} {...props} data-sentry-component="DialogFooter" data-sentry-source-file="dialog.tsx" />;
DialogFooter.displayName = "DialogFooter";
const DialogTitle = ({
  className,
  ...props
}: ComponentProps<typeof DialogPrimitive.Title>) => <DialogPrimitive.Title className={cn("text-lg font-semibold leading-none tracking-tight", className)} {...props} data-sentry-element="unknown" data-sentry-component="DialogTitle" data-sentry-source-file="dialog.tsx" />;
DialogTitle.displayName = DialogPrimitive.Title.displayName;
const DialogDescription = ({
  className,
  ...props
}: ComponentProps<typeof DialogPrimitive.Description>) => <DialogPrimitive.Description className={cn("text-placeholder-fg text-sm", className)} {...props} aria-describedby={undefined} data-sentry-element="unknown" data-sentry-component="DialogDescription" data-sentry-source-file="dialog.tsx" />;
DialogDescription.displayName = DialogPrimitive.Description.displayName;
export { Dialog, DialogPortal, DialogOverlay, DialogTrigger, DialogClose, DialogContent, DialogHeader, DialogFooter, DialogTitle, DialogDescription };
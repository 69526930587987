import { cn } from "@/lib/utils/cssUtils";
import { ComponentProps } from "react";

// TODO: support the styling classes used here
const Card = ({
  className,
  ...props
}: ComponentProps<"div">) => <div className={cn("rounded-xl border bg-primary text-primary-fg-body shadow", className)} {...props} data-sentry-component="Card" data-sentry-source-file="card.tsx" />;
Card.displayName = "Card";
const CardHeader = ({
  className,
  ...props
}: ComponentProps<"div">) => <div className={cn("flex flex-col space-y-1.5 p-6", className)} {...props} data-sentry-component="CardHeader" data-sentry-source-file="card.tsx" />;
CardHeader.displayName = "CardHeader";
const CardTitle = ({
  className,
  ...props
}: ComponentProps<"h3">) => <h3 className={cn("font-semibold leading-none tracking-tight", className)} {...props} data-sentry-component="CardTitle" data-sentry-source-file="card.tsx" />;
CardTitle.displayName = "CardTitle";
const CardDescription = ({
  className,
  ...props
}: ComponentProps<"p">) => <p className={cn("text-placeholder-fg text-sm", className)} {...props} data-sentry-component="CardDescription" data-sentry-source-file="card.tsx" />;
CardDescription.displayName = "CardDescription";
const CardContent = ({
  className,
  ...props
}: ComponentProps<"div">) => <div className={cn("p-6", "pt-0", className)} {...props} data-sentry-component="CardContent" data-sentry-source-file="card.tsx" />;
CardContent.displayName = "CardContent";
const CardFooter = ({
  className,
  ...props
}: ComponentProps<"div">) => <div className={cn("flex items-center p-6", "pt-0", className)} {...props} data-sentry-component="CardFooter" data-sentry-source-file="card.tsx" />;
CardFooter.displayName = "CardFooter";
export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent };
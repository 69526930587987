"use client";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { cn } from "@/lib/utils/cssUtils";
import { ComponentProps } from "react";
const TooltipProvider = TooltipPrimitive.Provider;
const Tooltip = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;
type CustomTooltipProps = {
  disableArrow?: boolean;
  backgroundColorTWUtilClassName?: string;
  arrowFillColorTWUtilClassName?: string;
};
const TooltipContent = ({
  className,
  sideOffset = 4,
  disableArrow = false,
  backgroundColorTWUtilClassName,
  arrowFillColorTWUtilClassName,
  ...props
}: ComponentProps<typeof TooltipPrimitive.Content> & CustomTooltipProps) => {
  return <TooltipPrimitive.Portal data-sentry-element="unknown" data-sentry-component="TooltipContent" data-sentry-source-file="tooltip.tsx">
      <TooltipPrimitive.Content sideOffset={sideOffset} className={cn("z-[500] overflow-hidden rounded-md bg-[#000000d9] px-3 py-1.5 text-xs text-white animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2", className, backgroundColorTWUtilClassName)} {...props} data-sentry-element="unknown" data-sentry-source-file="tooltip.tsx">
        {props.children}
        {!disableArrow && <TooltipPrimitive.Arrow className={cn(arrowFillColorTWUtilClassName)} />}
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>;
};
TooltipContent.displayName = TooltipPrimitive.Content.displayName;
export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };
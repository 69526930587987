"use client";

import * as PopoverPrimitive from "@radix-ui/react-popover";
import { cn } from "@/lib/utils/cssUtils";
import { ComponentProps } from "react";
import { Cross2Icon } from "@radix-ui/react-icons";
const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverAnchor = PopoverPrimitive.Anchor;
type CustomPopoverProps = {
  disableArrow?: boolean;
  showCloseButton?: boolean;
  backgroundColorTWUtilClassName?: string;
  arrowFillColorTWUtilClassName?: string;
  popoverElement?: HTMLElement;
  usePortal?: boolean;
};
const PopoverContent = ({
  className,
  align = "center",
  sideOffset = 4,
  disableArrow = false,
  showCloseButton = false,
  popoverElement,
  usePortal = true,
  arrowFillColorTWUtilClassName,
  backgroundColorTWUtilClassName,
  ...props
}: ComponentProps<typeof PopoverPrimitive.Content> & CustomPopoverProps) => {
  return usePortal ? <PopoverPrimitive.Portal container={popoverElement} data-sentry-element="unknown" data-sentry-component="PopoverContent" data-sentry-source-file="popover.tsx">
      <PopoverPrimitive.Content align={align} sideOffset={sideOffset} className={cn("text-popover-foreground z-[400] rounded-md border bg-primary p-4 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2", className, backgroundColorTWUtilClassName)} {...props} data-sentry-element="unknown" data-sentry-source-file="popover.tsx">
        {props.children}
        {!disableArrow && <PopoverPrimitive.Arrow className={cn(arrowFillColorTWUtilClassName)} />}
        {/* Show close button won't work if the popover open/close is being controled by state. You may need to implement that feature into here if needed. */}
        {showCloseButton && <PopoverPrimitive.Close className="absolute right-[5px] top-[5px] inline-flex size-[25px] cursor-default items-center justify-center rounded-full text-gray-800 outline-none hover:bg-gray-200 focus:shadow-[0_0_0_2px] focus:shadow-gray-400" aria-label="Close">
            <Cross2Icon />
          </PopoverPrimitive.Close>}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal> : <PopoverPrimitive.Content align={align} sideOffset={sideOffset} className={cn("text-popover-foreground z-[400] rounded-md border bg-primary p-4 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2", className, backgroundColorTWUtilClassName)} {...props} data-sentry-element="unknown" data-sentry-component="PopoverContent" data-sentry-source-file="popover.tsx">
      {props.children}
      {!disableArrow && <PopoverPrimitive.Arrow className={cn(arrowFillColorTWUtilClassName)} />}
      {/* Show close button won't work if the popover open/close is being controled by state. You may need to implement that feature into here if needed. */}
      {showCloseButton && <PopoverPrimitive.Close className="absolute right-[5px] top-[5px] inline-flex size-[25px] cursor-default items-center justify-center rounded-full text-gray-800 outline-none hover:bg-gray-200 focus:shadow-[0_0_0_2px] focus:shadow-gray-400" aria-label="Close">
          <Cross2Icon />
        </PopoverPrimitive.Close>}
    </PopoverPrimitive.Content>;
};
PopoverContent.displayName = PopoverPrimitive.Content.displayName;
export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor };
import {
  PatientMergePayload,
  EMRPatient,
  PatientAlternateContact,
  PatientDataModel,
  ValidatePatientNumberResponse,
  PatientIdentityVerification,
} from "@/lib/types/patient";
import customFetch from "./customFetch";
import { LesionItemType } from "@/lib/types/aiVerification";
import { WriteFileResultModel } from "./filesAPI";

type PatientHCNResponseObject = {
  patient?: EMRPatient;
  message: string;
  error?: string;
};

export type PatientNote = {
  id?: number;
  patientId?: number;
  name?: string;
  createdAt?: number;
  body: string | null;
  subject: string | null;
};

const savePatientData = async (savePatientData: PatientDataModel): Promise<PatientDataModel> => {
  const { patientData } = await customFetch(`/patient/save`, { body: savePatientData, method: "POST" });
  return patientData;
};

const getPatientsElastic = async (query: string): Promise<EMRPatient[]> => {
  const res = await customFetch(`/patient/search`, { body: { query }, method: "POST" });
  return res.patients.hits.hits.map((v: { _source: EMRPatient }) => v?._source) as EMRPatient[];
};

const getPatientDataById = async (patientId: number): Promise<EMRPatient> => {
  const { patient } = await customFetch(`/patient/patientId/${patientId}`);
  return patient;
};

const updatePatient = async (patientData: EMRPatient): Promise<EMRPatient> => {
  const { patient } = await customFetch(`/patient`, { body: patientData, method: "PUT" });
  return patient;
};

const mergePatient = async (mergeData: PatientMergePayload, patientMergedId: number): Promise<EMRPatient> => {
  const { patient } = await customFetch(`/patient/patientId/${patientMergedId}/action/merge`, { body: mergeData, method: "POST" });
  return patient;
};

const getPatientLesionHistory = async (patientId: number): Promise<LesionItemType[]> => {
  const { patientLesionHistories } = await customFetch(`/patient/patientId/${patientId}/lesions`);
  return patientLesionHistories;
};

const archivePatientLesionHistory = async (noteId: number): Promise<string> => {
  const { message } = await customFetch(`/patient/lesion/${noteId}/action/archive`, { method: "PATCH" });
  return message;
};

const getPatientAlternateContactsInfo = async (patientId: number): Promise<PatientAlternateContact[]> => {
  const { patientAlternateContacts } = await customFetch(`/patient/patientId/${patientId}/altcontacts`);
  return patientAlternateContacts;
};

const getPatientsAlternateContactsInfoByRelationId = async (relationId: number): Promise<PatientAlternateContact> => {
  const { patientAlternateContact } = await customFetch(`/patient/altcontact/${relationId}`);
  return patientAlternateContact;
};

const getPatientByHCN = async (patientHCN?: string, patientVC?: string): Promise<PatientHCNResponseObject> => {
  // undefined in the input props on purpose as it will still run as 404. its stupid, i know
  const response = await customFetch(`/patient/patientHCN/${patientHCN}/patientVC/${patientVC}`, undefined, undefined, {
    dontHandleResponse: true,
    responseType: "untouched",
  });
  console.log(`response: `, response);
  const data = await response.json();
  console.log(`data: `, data);
  return data;
};

const createPatient = async (patientData: Partial<EMRPatient>): Promise<EMRPatient> => {
  const { patient } = await customFetch(`/patient`, { body: patientData, method: "POST" });
  return patient;
};

const updatePatientAlternateContactsInfo = async (patientId: number, contacts: PatientAlternateContact[]): Promise<PatientAlternateContact[]> => {
  const { patientAlternateContactsExpanded } = await customFetch(`/patient/patientId/${patientId}/altcontacts`, { body: contacts, method: "PUT" });
  return patientAlternateContactsExpanded;
};

const validatePatientPhoneNumber = async (phone: string): Promise<ValidatePatientNumberResponse> => {
  const res = await customFetch(`/patient/validatenumber`, { body: { phone }, method: "POST" });
  return res;
};

const getPatientNotes = async (patientId: number): Promise<PatientNote[]> => {
  const { patientNotes } = await customFetch(`/patient/patientId/${patientId}/notes`);
  return patientNotes;
};

const addPatientNote = async (note: PatientNote): Promise<PatientNote> => {
  const { id, ...newNote } = note;
  const { patientNote } = await customFetch(`/patient/note`, { body: newNote, method: "POST" });
  return patientNote;
};

const getPatientIdentityVerificationInfo = async (patientId: number): Promise<PatientIdentityVerification[]> => {
  const { identityVerificationInfo } = await customFetch(`/patient/identityverification/${patientId}`);
  return identityVerificationInfo;
};

const unlockPatientIdentityVerification = async (patientId: number): Promise<void> => {
  return await customFetch(`/patient/identityverification/${patientId}`, { method: "PATCH" });
};

const uploadPatientImages = async (uploadPatientImagesForm: FormData): Promise<WriteFileResultModel> => {
  const response = await customFetch(
    `/files/images`,
    { body: uploadPatientImagesForm, method: "POST", headers: { "Content-Type": "multipart/form-data" }, stringifyBody: false },
    undefined,
    { responseType: "untouched" }
  );
  return response;
};

const uploadPatientFile = async (uploadPatientFileForm: FormData): Promise<WriteFileResultModel> => {
  const response = await customFetch(
    `/patient/files`,
    { body: uploadPatientFileForm, method: "POST", headers: { "Content-Type": "multipart/form-data" }, stringifyBody: false },
    undefined,
    { responseType: "untouched" }
  );
  return response;
};

const patientAPI = {
  savePatientData,
  getPatientsElastic,
  getPatientDataById,
  updatePatient,
  getPatientLesionHistory,
  archivePatientLesionHistory,
  getPatientAlternateContactsInfo,
  getPatientsAlternateContactsInfoByRelationId,
  updatePatientAlternateContactsInfo,
  mergePatient,
  getPatientByHCN,
  createPatient,
  validatePatientPhoneNumber,
  getPatientNotes,
  addPatientNote,
  getPatientIdentityVerificationInfo,
  unlockPatientIdentityVerification,
  uploadPatientFile,
  uploadPatientImages,
};

export default patientAPI;

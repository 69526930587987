import physicianAPI from "@/api/physicianAPI";
import { useQuery } from "@tanstack/react-query";
import { ReactNode, useMemo, useState } from "react";
import { PhysicianSingleLocationModel } from "@/lib/types/physician";
import { cn } from "@/lib/utils/cssUtils";
import { isEmpty, isNil } from "lodash";
import { Card as SCNCard, CardContent } from "@/components/ui/card";
import { ClassValue } from "clsx";
import { FuzzyCombobox, FuzzyComboboxItem } from "@/components/ui/fuzzyCombobox";
import { AddressBookPhysician } from "@/lib/types/addressBook";
import { getPhysicianLocationPhone, getPhysicianLocationFax } from "./legacy";
const generatePhysicianSingleLocationComboboxItem = (physicianSingleLocation: PhysicianSingleLocationModel, hideItem = false): PhysicianSingleLocationComboItem => {
  return {
    name: <>
        <SCNCard className="w-full rounded-md p-2 hover:bg-gray-100">
          <CardContent className="flex flex-col gap-1.5 p-0 text-gray-700">
            <div className="text-base font-bold">
              {[physicianSingleLocation.physician?.lastName, physicianSingleLocation.physician?.firstName].join(", ")}
            </div>
            <div>{physicianSingleLocation.physicianLocation?.name}</div>
            <div>
              {[physicianSingleLocation.physicianLocation?.streetAddress, `${physicianSingleLocation.physicianLocation?.city} ${physicianSingleLocation.physicianLocation?.province}`, physicianSingleLocation.physicianLocation?.postal?.toUpperCase()].join(", ")}
            </div>
            <div>{`P: ${getPhysicianLocationPhone(physicianSingleLocation.physicianLocation!)}`}</div>
            <div>{`F: ${getPhysicianLocationFax(physicianSingleLocation.physicianLocation!)}`}</div>
          </CardContent>
        </SCNCard>
      </> as ReactNode,
    value: physicianSingleLocation.physicianAndLocationId,
    displayValue: `${physicianSingleLocation.physician!.lastName}, ${physicianSingleLocation.physician!.firstName}`,
    otherData: physicianSingleLocation as PhysicianSingleLocationModel,
    hide: hideItem
  } satisfies PhysicianSingleLocationComboItem;
};
export interface PhysicianSingleLocationComboItem extends FuzzyComboboxItem {
  otherData: PhysicianSingleLocationModel;
}
interface PhysicanDropdownProps {
  physicianDropdownKey: string;
  onSelectPhysician?: (physicianSingleLocation: PhysicianSingleLocationModel | null) => void;
  physicianId?: number | null;
  physicianLocationId?: string | null;
  containerClassName?: ClassValue;
  popoverTriggerClassName?: ClassValue;
  popoverContentClassName?: ClassValue;
  popoverContentItemClassName?: ClassValue;
  popoverContentSelectedItemClassName?: ClassValue;
  popoverContentSelectedItemTextClassName?: ClassValue;
  popoverContentAlignment?: "start" | "center" | "end";
  placeholder?: string;
  popoverContentUsePortal?: boolean;
  allowRemove?: boolean;
  showDisplayValue?: boolean;
  disabled?: boolean;
}
export const PhysicianDropdown = ({
  physicianDropdownKey,
  onSelectPhysician,
  physicianId,
  physicianLocationId,
  containerClassName,
  popoverTriggerClassName,
  popoverContentClassName,
  popoverContentItemClassName,
  popoverContentSelectedItemClassName,
  popoverContentSelectedItemTextClassName,
  popoverContentAlignment = "start",
  placeholder = "Select a physician",
  popoverContentUsePortal = true,
  allowRemove = true,
  showDisplayValue = true,
  disabled
}: PhysicanDropdownProps) => {
  // TODO: may want to debounce searchTerm
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const selectedPhysicianQueryObject = usePhysicianQueryObject({
    physicianDropdownKey,
    physicianId,
    physicianLocationId
  });
  const searchPhysiciansQueryObject = useQuery({
    queryKey: ["physicians", "search", physicianDropdownKey, searchTerm],
    queryFn: () => physicianAPI.getAddressPhysiciansElastic(searchTerm ?? ""),
    enabled: !isEmpty(searchTerm ?? ""),
    select: (physicians: AddressBookPhysician[]) => {
      return physicians.filter(physician => (physician.locations ?? []).length > 0).map(physician => physician.locations!.filter(location => !isNil(location.id) && location.active).map(location => ({
        physicianAndLocationId: `${physician.id},${location.id}`,
        physician,
        physicianLocation: location
      }) satisfies PhysicianSingleLocationModel)).flat().map(physicianEntry => generatePhysicianSingleLocationComboboxItem(physicianEntry));
    },
    staleTime: 0
  });
  const consolidatedData = useMemo(() => [...(isNil(selectedPhysicianQueryObject.data) || isNil(physicianId) ? [] : [selectedPhysicianQueryObject.data]), ...(searchPhysiciansQueryObject.data ?? [])], [physicianId, physicianLocationId, selectedPhysicianQueryObject.data, searchPhysiciansQueryObject.data]);
  return <>
      <FuzzyCombobox allowRemove={allowRemove} currentValue={isNil(physicianId) ? null : !isNil(physicianId) && isNil(physicianLocationId) ? consolidatedData[0]?.value ?? null : `${physicianId},${physicianLocationId}`} onSelect={value => {
      const physician = searchPhysiciansQueryObject.data?.find(item => item.value === value);
      if (physician) {
        onSelectPhysician && onSelectPhysician(physician.otherData);
      }
    }} onDeselect={() => {
      onSelectPhysician && onSelectPhysician(null);
      setSearchTerm(null);
    }} showDisplayValue={showDisplayValue} placeholder={placeholder} containerClassName={containerClassName} popoverTriggerClassName={popoverTriggerClassName} popoverContentClassName={popoverContentClassName} popoverContentItemClassName={cn("hover:!bg-primary !bg-primary", popoverContentItemClassName)} popoverContentSelectedItemClassName={cn("bg-primary", popoverContentSelectedItemClassName)} popoverContentSelectedItemTextClassName={cn("[&>div]:bg-gray-300", popoverContentSelectedItemTextClassName)} popoverContentAlignment={popoverContentAlignment} data={consolidatedData} searchText={searchTerm} setSearchText={setSearchTerm} popoverContentUsePortal={popoverContentUsePortal} dropdownHeight={consolidatedData.filter(v => !v.hide).length === 0 ? null : 400} disabled={disabled} isDataLoading={selectedPhysicianQueryObject.isLoading} data-sentry-element="FuzzyCombobox" data-sentry-source-file="physicianDropdown.tsx" />
    </>;
};
interface PhysicianQueryObjectInput {
  physicianDropdownKey: string;
  physicianId?: number | null;
  physicianLocationId?: string | null;
}
export const usePhysicianQueryObject = ({
  physicianDropdownKey,
  physicianId,
  physicianLocationId
}: PhysicianQueryObjectInput) => {
  return useQuery({
    queryKey: ["physician", physicianDropdownKey, {
      physicianId,
      physicianLocationId
    }],
    queryFn: () => physicianAPI.getAddressBookPhysicianById(physicianId!),
    select: physician => {
      const location = !isNil(physicianLocationId) ? physician.locations!.find(location => location.id === physicianLocationId) : physician.locations!.filter(location => !isNil(location.id) && location.active).at(0)!;
      const res = {
        physician,
        physicianLocation: location,
        physicianAndLocationId: `${physician.id},${location!.id}`
      } satisfies PhysicianSingleLocationModel as PhysicianSingleLocationModel;
      return generatePhysicianSingleLocationComboboxItem(res, true);
    },
    enabled: !isNil(physicianId),
    staleTime: 0
  });
};
"use client";

import { type DialogProps } from "@radix-ui/react-dialog";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Command as CommandPrimitive } from "cmdk";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { cn } from "@/lib/utils/cssUtils";
import { ComponentProps, HTMLAttributes } from "react";
const Command = ({
  className,
  ...props
}: ComponentProps<typeof CommandPrimitive>) => <CommandPrimitive className={cn("bg-popover text-component-fg flex size-full flex-col overflow-hidden rounded-md", className)} {...props} data-sentry-element="CommandPrimitive" data-sentry-component="Command" data-sentry-source-file="command.tsx" />;
Command.displayName = CommandPrimitive.displayName;
interface CommandDialogProps extends DialogProps {}
const CommandDialog = ({
  children,
  ...props
}: CommandDialogProps) => {
  return <Dialog {...props} data-sentry-element="Dialog" data-sentry-component="CommandDialog" data-sentry-source-file="command.tsx">
      <DialogContent className="overflow-hidden p-0" hideTitle data-sentry-element="DialogContent" data-sentry-source-file="command.tsx">
        <Command className="[&_[cmdk-group-heading]]:text-placeholder-fg [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5" data-sentry-element="Command" data-sentry-source-file="command.tsx">
          {children}
        </Command>
      </DialogContent>
    </Dialog>;
};
const CommandInput = ({
  className,
  ...props
}: ComponentProps<typeof CommandPrimitive.Input>) => <div className="flex items-center border-b px-3" cmdk-input-wrapper="" data-sentry-component="CommandInput" data-sentry-source-file="command.tsx">
    <MagnifyingGlassIcon className="mr-2 size-4 shrink-0 opacity-50" data-sentry-element="MagnifyingGlassIcon" data-sentry-source-file="command.tsx" />
    <CommandPrimitive.Input className={cn("placeholder:text-placeholder-fg flex h-10 w-full rounded-md bg-transparent py-3 text-sm outline-none disabled:cursor-not-allowed disabled:opacity-50", className)} {...props} data-sentry-element="unknown" data-sentry-source-file="command.tsx" />
  </div>;
CommandInput.displayName = CommandPrimitive.Input.displayName;
const CommandList = ({
  className,
  ...props
}: ComponentProps<typeof CommandPrimitive.List>) => <CommandPrimitive.List className={cn("max-h-[300px] overflow-y-auto overflow-x-hidden", className)} {...props} data-sentry-element="unknown" data-sentry-component="CommandList" data-sentry-source-file="command.tsx" />;
CommandList.displayName = CommandPrimitive.List.displayName;
const CommandEmpty = (props: ComponentProps<typeof CommandPrimitive.Empty>) => <CommandPrimitive.Empty className="py-6 text-center text-sm" {...props} data-sentry-element="unknown" data-sentry-component="CommandEmpty" data-sentry-source-file="command.tsx" />;
CommandEmpty.displayName = CommandPrimitive.Empty.displayName;
const CommandGroup = ({
  className,
  ...props
}: ComponentProps<typeof CommandPrimitive.Group>) => <CommandPrimitive.Group className={cn("text-foreground [&_[cmdk-group-heading]]:text-placeholder-fg overflow-hidden p-1 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium", className)} {...props} data-sentry-element="unknown" data-sentry-component="CommandGroup" data-sentry-source-file="command.tsx" />;
CommandGroup.displayName = CommandPrimitive.Group.displayName;
const CommandSeparator = ({
  className,
  ...props
}: ComponentProps<typeof CommandPrimitive.Separator>) => <CommandPrimitive.Separator className={cn("-mx-1 h-px bg-border", className)} {...props} data-sentry-element="unknown" data-sentry-component="CommandSeparator" data-sentry-source-file="command.tsx" />;
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;
const CommandItem = ({
  className,
  ...props
}: ComponentProps<typeof CommandPrimitive.Item>) => <CommandPrimitive.Item className={cn("relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none aria-selected:bg-gray-200 aria-selected:text-gray-800 data-[disabled='true']:pointer-events-none data-[disabled='true']:opacity-50", className)} {...props} data-sentry-element="unknown" data-sentry-component="CommandItem" data-sentry-source-file="command.tsx" />;
CommandItem.displayName = CommandPrimitive.Item.displayName;
const CommandShortcut = ({
  className,
  ...props
}: ComponentProps<"span">) => {
  return <span className={cn("text-placeholder-fg ml-auto text-xs tracking-widest", className)} {...props} data-sentry-component="CommandShortcut" data-sentry-source-file="command.tsx" />;
};
CommandShortcut.displayName = "CommandShortcut";
export { Command, CommandDialog, CommandInput, CommandList, CommandEmpty, CommandGroup, CommandItem, CommandShortcut, CommandSeparator };